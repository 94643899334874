import React from 'react';
import { useQuery } from 'seed/gql'
import queryString from 'query-string'
import { Loading } from 'seed/helpers'
import styles from 'styles/css/dashboard/Print.module.css';

function Dashboard(props) {
  const params = new URLSearchParams(props.location.search);
  if (params.get("id") != undefined){
    sessionStorage.setItem('id', params.get("id"));
    sessionStorage.setItem('token', params.get("token"));
    window.location.href = "/print"
  }
  const userId = sessionStorage.getItem("id")
  const qClients = useQuery(`{
    clients {     
      name
    }
  
  }`, `team.users = ${userId}`, { orderBy: "-name" });

  if (qClients.error) return "Error"
  if (qClients.isLoading) return <Loading />

  let { clients = [] } = qClients.data
  let filter = clients.map(c => c.id).toString()
  if (filter == "") return <div>No hay clientes registrados</div>
  let filterUri = encodeURI(`filter=_clientes/id in (${filter})`);
  let pageId = "ReportSection5b71ea2c51c5bf45fd56"
  return <div className={styles.module} id="dashboard">
    <iframe className={styles.frame}
      title="Panel"
      src={`https://app.powerbi.com/view?r=eyJrIjoiZjdkNzZkYTctMDFiYy00ZmI1LWE0M2QtNzAyZGE1ZDZlYzUyIiwidCI6IjVhM2MyYTI2LWRmMTMtNDUyNC05ZGYyLTA3ZDlhNGQ0YTdkMCJ9
    &pageName=${pageId}&${filterUri}`}></iframe>  
    <div className={styles.footer}></div>  
  </div>
}


export default Dashboard;
